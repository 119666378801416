<template>
  <div>
    <div class="uk-flex uk-flex-between uk-flex-middle">
      <div class="uk-text-large">Restricción de productos</div>
      <div>
        <button
          class="uk-button uk-button-primary uk-border-rounded"
          href="#edit-restriction-modal"
          uk-toggle
          @click="setNewMode"
        >
          Crear nueva restricción
        </button>
      </div>
    </div>

    <table class="uk-table uk-table-middle uk-table-hover uk-table-divider">
      <thead>
        <tr>
          <th>Tipo</th>
          <th>Valor</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="restriction in restrictions" :key="restriction.id">
          <td>{{ restriction.type }}</td>
          <td>{{ restriction.target }}</td>
          <td>
            <div class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <button
                  class="uk-button uk-button-default"
                  uk-icon="pencil"
                  href="#edit-restriction-modal"
                  uk-toggle
                  @click="setEditMode(restriction)"
                ></button>
                <button
                  class="uk-button uk-button-default"
                  uk-icon="trash"
                  href="#delete-restriction-modal"
                  uk-toggle
                  @click="setEditMode(restriction)"
                ></button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      id="edit-restriction-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Crear</span>
          <span v-else>Editar</span>
          restricción
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">Tipo</div>
            <select
              name="Tipo"
              v-model="model.type"
              class="uk-select uk-border-rounded"
            >
              <option
                v-for="(type, index) in restrictionTypes"
                :key="index"
                :value="type"
              >
                {{ type }}
              </option>
            </select>
            <div>{{ errors.length !== 0 ? errors[0] : "" }}</div>
          </ValidationProvider>

          <ValidationProvider
            name="Objetivo"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Objetivo</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Objetivo"
              v-model="model.target"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              :disabled="invalid || isLoading"
              @click="mode === 'new' ? store() : update()"
            >
              <span v-if="isLoading" uk-spinner></span>
              <span v-else>
                <span v-if="mode === 'new'">Crear</span>
                <span v-else>Editar</span>
                restricción
              </span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <div
      id="delete-restriction-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
        v-if="selectedRestriction"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          ¿Está seguro que desea eliminar la restricción de tipo
          <span class="uk-text-bold">{{ selectedRestriction.type }}</span>
          con valor
          <span class="uk-text-bold">{{ selectedRestriction.target }}</span
          >?
        </div>

        <div class="uk-text-center uk-margin-top">
          <button
            class="uk-button uk-button-danger uk-border-rounded"
            @click="destroy"
            :disabled="isLoading"
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Eliminar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
const blankModel = {
  type: "",
  target: "",
};

export default {
  name: "ProductRestrictions",

  data() {
    return {
      mode: "new",
      model: this._.cloneDeep(blankModel),
      isLoading: false,
      selectedRestriction: null,
      restrictions: null,
      restrictionTypes: null,
    };
  },

  mounted() {
    this.fetchRestrictions();
  },

  methods: {
    resetModel() {
      this.model = this._.cloneDeep(blankModel);
    },
    setNewMode() {
      this.mode = "new";
      this.selectedRestriction = null;
      this.resetModel();
    },
    setEditMode(restriction) {
      this.mode = "edit";
      this.selectedRestriction = restriction;
      this.model.type = restriction.type;
      this.model.target = restriction.target;
    },
    fetchRestrictions() {
      this.isLoading = true;
      this.axios
        .get("products/restrictions")
        .then(({ data: { restrictions, restrictionTypes } }) => {
          this.restrictions = restrictions;
          this.restrictionTypes = restrictionTypes;
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    store() {
      this.isLoading = true;
      this.axios
        .post("/products/restrictions", this.model)
        .then(() => {
          this.fetchRestrictions();
          UIkit.modal("#edit-restriction-modal").hide();
          this.resetModel();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      this.axios
        .put(
          "/products/restrictions/" + this.selectedRestriction.id.toString(),
          this.model
        )
        .then(() => {
          this.fetchRestrictions();
          UIkit.modal("#edit-restriction-modal").hide();
          this.resetModel();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    destroy() {
      this.isLoading = true;
      this.axios
        .delete(
          "/products/restrictions/" + this.selectedRestriction.id.toString()
        )
        .then(() => {
          this.fetchRestrictions();
          UIkit.modal("#delete-restriction-modal").hide();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
